import { Component, Input } from '@angular/core';
import { ContextService } from 'src/app/services/context.service';

@Component({
  selector: 'app-modules-list',
  templateUrl: './modules-list.component.html',
  styleUrls: ['./modules-list.component.scss'],
})
export class ModulesListComponent {

  @Input() tarifKey: string;

  constructor(public cs: ContextService) { }

}
