import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(public userService: UserService, public navCtrl: NavController) { }

    canActivate(): boolean {
        if (!this.userService.isLoggedIn || !this.userService.userSigned) {
            this.navCtrl.navigateRoot('/home');
            return false;
        }
        return true;
    }
}
