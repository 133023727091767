import { Component } from '@angular/core';
import { ContextService } from 'src/app/services/context.service';

@Component({
  selector: 'app-tarifs-calculation',
  templateUrl: './tarifs-calculation.component.html',
  styleUrls: ['./tarifs-calculation.component.scss'],
})
export class TarifsCalculationComponent {

  constructor(public cs: ContextService) { }

}
