import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss'],
})
export class AddressModalComponent {

  @Input() streets: any;

  constructor(public modalCtrl: ModalController) { }

  selectAddress(street: any) {
    this.modalCtrl.dismiss({
      selectedAddress: street
    });
  }

}
