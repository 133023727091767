import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyRender'
})
export class CurrencyRenderPipe implements PipeTransform {

  transform(value: any, ...args: any) {
    return ((parseFloat(value) / 100).toFixed(2) + ' €').replace('.', ',');
  }

}
