import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent {

  @Input() modalId: string;
  @Input() label: string;
  @Input() fieldValue: string;
  @Input() disabled: boolean;

  constructor() { }

}
