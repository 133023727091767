import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tarif-tabs',
  templateUrl: './tarif-tabs.component.html',
  styleUrls: ['./tarif-tabs.component.scss'],
})
export class TarifTabsComponent {

  @Input() tabLabelFirst: string;
  @Input() tabLabelSecond: string;
  @Input() active: string;
  @Output() tabActivated = new EventEmitter<string>();

  constructor() { }

  onTabPress(variant: string) {
    this.tabActivated.emit(variant);
  }

}
