import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

  transform(value: any, ...args: any) {
    return `${parseInt(value, 10).toLocaleString('de')} €`;
  }

}
