import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { NotificationService } from './services/notification.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { PipesModule } from './pipes/pipes.module';
import { BrMaskerModule } from 'br-mask';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ backButtonText: 'Zurück', swipeBackEnabled: false, mode: 'ios' }),
    AppRoutingModule,
    ComponentsModule,
    PipesModule,
    HttpClientModule,
    BrMaskerModule,
  ],
  providers: [
    AuthGuard,
    InAppBrowser,
    NotificationService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
