import { Injectable } from '@angular/core';
import * as IBAN from 'iban';
import { ContextService } from './context.service';

@Injectable({
    providedIn: 'root'
})
export class ValidationService {

    constructor(private cs: ContextService) { }

    validateField(fieldValue: any): string {
        return (!this.cs.isFilled(fieldValue) || fieldValue === '') ? 'invalid' : '';
    }

    validateQm(qm: any): string {
        const hasValue = this.validateField(qm);
        return hasValue === '' ? (!this.cs.isFilled(qm) || qm < 0 || qm > 300) ? 'invalid' : '' : 'invalid';
    }

    validatePlz(plz: any): string {
        const hasValue = this.validateField(plz);
        return hasValue === '' ? (plz.toString().length !== 5) ? 'invalid' : '' : 'invalid';
    }

    validateAlter(alter: any): string {
        const hasValue = this.validateField(alter);
        return hasValue === '' ? (!this.cs.isFilled(alter) || alter < 0 || alter > 60) ? 'invalid' : '' : 'invalid';
    }

    validateIBan(iban: any): string {
        const hasValue = this.validateField(iban);
        return hasValue === '' ? (iban.length !== 27 || !IBAN.isValid(iban)) ? 'invalid' : '' : 'invalid';
    }

    validateEmail(email: any) {
        return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) ? '' : 'invalid';
    }

    validateAnzahl(num: any) {
        const hasValue = this.validateField(num);
        return hasValue === '' ? (!this.cs.isFilled(num) || num < 0 || num > 2) ? 'invalid' : '' : 'invalid';
    }

    validateNumber(num: any) {
        const hasValue = this.validateField(num);
        return hasValue === '' ? (!this.cs.isFilled(num) || num < 0) ? 'invalid' : '' : 'invalid';
    }

    validateVepNummer(num: any) {
        const hasValue = this.validateField(num);
        return hasValue === '' ? (!this.cs.isFilled(num) || num.length > 5) ? 'invalid' : '' : 'invalid';
    }
}
