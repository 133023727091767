import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CurrencyRenderPipe } from './currency-render.pipe';
import { CustomCurrencyPipe } from './custom-currency.pipe';

@NgModule({
    declarations: [CurrencyRenderPipe, CustomCurrencyPipe],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule
    ],
    exports: [CurrencyRenderPipe, CustomCurrencyPipe],
})

export class PipesModule { }
