import { Component, Input } from '@angular/core';
import { ContextService } from 'src/app/services/context.service';

@Component({
  selector: 'app-module-item',
  templateUrl: './module-item.component.html',
  styleUrls: ['./module-item.component.scss'],
})
export class ModuleItemComponent {

  @Input() tarifKey: string;
  @Input() moduleName: string;
  @Input() moduleKey: string;

  constructor(public cs: ContextService) { }
}
