import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-standard-button',
  templateUrl: './standard-button.component.html',
  styleUrls: ['./standard-button.component.scss'],
})
export class StandardButtonComponent {

  @Input() buttonText: string;
  @Input() isDisabled = false;
  @Input() align = false;
  @Output() pressed = new EventEmitter<boolean>();

  constructor() { }

  onButtonPress() {
    this.pressed.emit(true);
  }

}
