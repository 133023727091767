import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { UserService } from '../services/user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private userService: UserService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        const authToken = localStorage.getItem('authToken');
        if (isApiUrl && authToken) {
            const cloned = request.clone({ headers: request.headers.set('Authorization', `Bearer ${authToken}`) });
            return next.handle(cloned).pipe(catchError((err, caught: Observable<HttpEvent<any>>) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    this.userService.logout();
                    return of(err as any);
                }
                throw err;
            }));
        } else {
            return next.handle(request);
        }
    }
}
